import React from "react";
import { Parallax } from "react-parallax";
import Layout from "../components/layouts";
import headerDonate from "../images/header-donate.jpg";
import moduleAmazonSmile from "../images/module-amazon-smile.jpg";
import modulePatreon from "../images/module-patreon.jpg";
import modulePaypal from "../images/module-paypal.jpg";
import moduleGift from "../images/module-gift.jpg";
// import tshirtFriends from "../images/product-tshirt-friends.jpg";
// import productSticker from "../images/product-sticker.jpg";
// import stickerSlow from "../images/product-sticker-slow.jpg";
// import tshirtWet from "../images/product-tshirt-wet.jpg";
// import tshirtReal from "../images/product-tshirt-real-1.jpg";
// import tshirtNature from "../images/product-tshirt-nature-1.jpg";
// import minibin from "../images/product-minibin-1.png";
// import stickerPolar from "../images/product-sticker-polar.jpg";
// import hatPolar from "../images/product-hat-polar-1.jpg";
// import tshirtEclipse from "../images/product-tshirt-eclipse-1.jpg";
// import magnetPolar from "../images/product-magnet-polar.jpg";
// import comicEnergy from "../images/product-comic-energy-1.jpg";



const Donate = () => {
    const scrollToSignup = () => {
      window.scrollTo(0, document.getElementById("shop").offsetTop);
    }

    return (
      <Layout navBg>
        <div className="donate-page">
          <Parallax className="page-header" bgImage={headerDonate} strength={400}>
              <div style={{height: '100vh', maxHeight: '550px'}}>
                  <div className="page-header-overlay" />
                  <h1 className="page-header-title white">Donate</h1>
              </div>
          </Parallax>
          <div className="donate-body">
              <div className="donate-intro">
                  <p>As Global Inheritance continues to work towards creating more inspiring programs,
                      impacting more and more young people, and affecting change across multiple platforms,
                      we seek support from partners, friends, and family. Our organization would not be where it
                      is today nor the experiences we create as successful as they are without the shared passion,
                      mentality, and care of those who contribute to all that is Global Inheritance.</p>
              </div>

              <div className="modules-container">
                  <div className="modules-container-inner flex">
                      <div className="module" id="module-1">
                          <div className="module-shadow-container">
                              <div className="thumb" style={{backgroundImage: `url(${moduleAmazonSmile})`}}>
                                  
                              </div>

                              <div className="inner">
                                  <h1>Amazon Smile</h1>
                                  <p>Support us by adding us on Amazon Smile. Amazon donates 0.5% of your Amazon Smile purchases to Global Inheritance.</p>
                              </div>

                              <a className="no-effects" href="http://smile.amazon.com/ch/55-0904687" target="_blank">
                                  <div className="btn small primary">
                                      <span>Smile</span>
                                  </div>
                              </a>
                          </div>
                      </div>
                      <div className="module" id="module-2">
                          <div className="module-shadow-container">
                              <div className="thumb" style={{backgroundImage: `url(${modulePatreon})`}}>
                                  
                              </div>

                              <div className="inner">
                                  <h1>Patreon</h1>
                                  <p>Become a patron of Global Inheritance.  Receive insider news, special invites to exclusive online meet-ups, limited edition merchandise and more!</p>
                              </div>

                              <a className="no-effects" href="https://www.patreon.com/globalinheritance" target="_blank">
                                  <div className="btn small primary">
                                      <span>Donate</span>
                                  </div>
                              </a>
                          </div>
                      </div>
                      <div className="module" id="module-3">
                          <div className="module-shadow-container">
                              <div className="thumb" style={{backgroundImage: `url(${modulePaypal})`}}>
                                  
                              </div>

                              <div className="inner">
                                  <h1>Paypal</h1>
                                  <p>Make a donation and feed the Global Inheritance piggy bank. All donations go towards the creation and execution of Global Inheritance programs.</p>
                              </div>

                              <a className="no-effects" href="https://www.paypal.com/donate?hosted_button_id=AHMQXER2NLV3J" target="_blank">
                                  <div className="btn small primary">
                                      <span>Donate</span>
                                  </div>
                              </a>
                          </div>
                      </div>
                      <div className="module" id="module-4">
                          <div className="module-shadow-container">
                              <div className="thumb" style={{backgroundImage: `url(${moduleGift})`}}>
                                  
                              </div>

                              <div className="inner">
                                  <h1>Gift</h1>
                                  <p>Pick up a limited edition Global Inheritance item today.</p>
                              </div>
                              <a className="no-effects" href="https://globalinheritance.myshopify.com/" target="_blank">
                                <div className="btn small primary" style={{'bottom': 20}} onClick={scrollToSignup}>
                                    <span>Get a Gift</span>
                                </div>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>

              {/* <div className="shop" id="shop">
                  <div className="home-section-title-container">
                      <h1 className="home-section-title">Shop</h1>
                      <span />
                  </div>
                  <div className="shop-product-container">
                      <div className="shop-product" id="why-cant-we-be-friends">
                          <div className="shop-product-image" style={{backgroundImage: `url(${tshirtFriends})`}}></div>
                          <div className="shop-product-title">Why Can't We Be Friends T-Shirt</div>
                          <div className="shop-product-price">$25.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'SHIRT_FRIENDS'}
                            data-item-price={'25.00'}
                            data-item-image={tshirtFriends}
                            data-item-name={'Why Cant We Be Friends T-Shirt'}
                            data-item-custom1-name={"Color"}
                            data-item-custom1-options={"White|Organic"}
                            data-item-custom2-name={"Size"}
                            data-item-custom2-options={"Small|Medium|Large|X Large"}
                            data-item-description={'Earth Month 2018 Limited Edition shirt designed by Luke McGarry'}
                            data-item-weight={200}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                              <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="global-inheritance-sticker">
                          <div className="shop-product-image" style={{backgroundImage: `url(${productSticker})`}}></div>
                          <div className="shop-product-title">Global Inheritance Sticker</div>
                          <div className="shop-product-price">$2.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects no-effects'
                            data-item-id={'STICKER_GI'}
                            data-item-price={'2.00'}
                            data-item-image={productSticker}
                            data-item-name={'Global Inheritance Sticker'}
                            data-item-description={'3in. x 2in.'}
                            data-item-weight={100}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                              <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="slow-your-flow-sticker">
                          <div className="shop-product-image" style={{backgroundImage: `url(${stickerSlow})`}}></div>
                          <div className="shop-product-title">Slow Your Flow Sticker</div>
                          <div className="shop-product-price">$2.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'STICKER_SLOW'}
                            data-item-price={'2.00'}
                            data-item-image={stickerSlow}
                            data-item-name={'Slow Your Flow Sticker'}
                            data-item-description={'3in. x 4in.'}
                            data-item-weight={100}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                            <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="drought-friendly-wet-tshirt">
                          <div className="shop-product-image" style={{backgroundImage: `url(${tshirtWet})`}}></div>
                          <div className="shop-product-title">Drought Friendly Wet T-Shirt - Unisex, Organic Cotton</div>
                          <div className="shop-product-price">$20.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'SHIRT_WET'}
                            data-item-price={'20.00'}
                            data-item-image={tshirtWet}
                            data-item-name={'Drought Friendly Wet T-Shirt'}
                            data-item-custom2-name={"Size"}
                            data-item-custom2-options={"X Small|Small|Medium|Large|X Large"}
                            data-item-weight={100}
                            data-item-description={'STAY DRY THIS SUMMER & HELP FIGHT THE DROUGHT! THESE DROUGHT FRIENDLY ORGANIC SHIRTS ARE ONLY A $20 DONATION AND INCLUDES A SLOW YOUR FLOW STICKER. 100% OF THE FUNDS SUPPORT THE RAIN SUPREME WATER EDUCATION TOUR. EMAIL PB@GLOBALINHERITANCE.ORG TO LEARN MORE ABOUT THE RAIN SUPREME PROGRAM.'}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                              <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="keep-it-real-tshirt">
                          <div className="shop-product-image" style={{backgroundImage: `url(${tshirtReal})`}}></div>
                          <div className="shop-product-title">Keep It Real Organic Tee</div>
                          <div className="shop-product-price">$20.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'SHIRT_REAL'}
                            data-item-price={'20.00'}
                            data-item-image={tshirtReal}
                            data-item-name={'Keep It Real Organic Tee'}
                            data-item-custom1-name={"Color"}
                            data-item-custom1-options={"Red|Gray"}
                            data-item-custom2-name={"Size"}
                            data-item-weight={100}
                            data-item-custom2-options={"X Small|Small|Medium|Large|X Large|2X Large"}
                            data-item-description={'Support Global Inheritance & the 80’s!  Keep it real in this soft organic t-shirt with Rubik, Alf, Uni the Unicorn, Hamburglar, Cap’n Crunch and other fictional characters from your past. Learn from the past and help us address real issues in the world today. 100% Organic Cotton by Alternative Apparel.'}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                              <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="saving-nature-tshirt">
                          <div className="shop-product-image" style={{backgroundImage: `url(${tshirtNature})`}}></div>
                          <div className="shop-product-title">Saving Nature Tee</div>
                          <div className="shop-product-price">$20.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'SHIRT_NATURE'}
                            data-item-price={'20.00'}
                            data-item-image={tshirtNature}
                            data-item-name={'Saving Nature Tee'}
                            data-item-custom1-name={"Color"}
                            data-item-custom1-options={"White|Gray"}
                            data-item-custom2-name={"Size"}
                            data-item-weight={100}
                            data-item-custom2-options={"X Small|Small|Medium|Large|X Large|2X Large|3X Large"}
                            data-item-description={'Global Inheritance’s polar bear mascot, aka DJ Super Chill, has hit the mixer to join his partner in crime – DJ Smokin’ Hot. This organic t-shirt highlights the Saving Nature duo and their tour around the world’s most endangered places. Tour stops include the Great Barrier Reef, Borneo, Amazon Rainforest, Congo, Galapagos Islands, and more! Rock this super soft t-shirt and support nature’s beautiful, yet threatened hot spots. 100% Organic Cotton by Alternative Apparel.'}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                              <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="diy-aor-kit">
                          <div className="shop-product-image" style={{backgroundImage: `url(${minibin})`}}></div>
                          <div className="shop-product-title">DIY #ArtOfRecycling Kit</div>
                          <div className="shop-product-price">$20.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'DIY_MINIBIN'}
                            data-item-price={'20.00'}
                            data-item-image={minibin}
                            data-item-name={'DIY #ArtOfRecycling Kit'}
                            data-item-weight={100}
                            data-item-description={'Design your own personal recycling reminder by ordering a Mini Bin design kit! These Mini Recycling Bins provide you the perfect canvas to showcase your skills. Each kit comes with tips on how to turn your recycling bin into a masterpiece. If you would like to submit your redesigned Mini Bin for future TRASHed (TRASH Education) events, please post a photo to our Instagram or Facebook for consideration. Mini Bins are made of 50% recycled plastic.'}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                              <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="polar-bear-flag-sticker">
                          <div className="shop-product-image" style={{backgroundImage: `url(${stickerPolar})`}}></div>
                          <div className="shop-product-title">Polar Bear Sticker</div>
                          <div className="shop-product-price">$2.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'STICKER_POLAR'}
                            data-item-price={'2.00'}
                            data-item-image={stickerPolar}
                            data-item-name={'Polar Bear Flag Sticker'}
                            data-item-description={'4in x 3in'}
                            data-item-weight={100}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                            <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="polar-bear-trucker-hat">
                          <div className="shop-product-image" style={{backgroundImage: `url(${hatPolar})`}}></div>
                          <div className="shop-product-title">Polar Bear Trucker Hat</div>
                          <div className="shop-product-price">$20.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'HAT_POLAR'}
                            data-item-price={'20.00'}
                            data-item-image={hatPolar}
                            data-item-name={'Polar Bear Trucker Hat'}
                            data-item-custom1-name={"Color"}
                            data-item-weight={100}
                            data-item-custom1-options={"Red|Blue|Black|Navy|Red & Blue"}
                            data-item-description={'What better way to support your favorite charity organization! The topper to your next outfit, these one of kind hats are perfect for any occasion – especially if you’re in a Golden State of mind. Donate now to receive your hat and keep on truckin’!'}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                            <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="polar-eclipse-tshirt">
                          <div className="shop-product-image" style={{backgroundImage: `url(${tshirtEclipse})`}}></div>
                          <div className="shop-product-title">Polar Eclipse Organic Tee</div>
                          <div className="shop-product-price">$20.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'SHIRT_ECLIPSE'}
                            data-item-price={'20.00'}
                            data-item-image={tshirtEclipse}
                            data-item-name={'Polar Eclipse Organic Tee'}
                            data-item-custom1-name={"Color"}
                            data-item-custom1-options={"Olive|Red"}
                            data-item-custom2-name={"Size"}
                            data-item-weight={100}
                            data-item-custom2-options={"X Small|Small|Medium|Large|X Large|2X Large"}
                            data-item-description={'The ice is disappearing and certain people (and polar bears) are noticing. Help us spread the word by rocking our Polar Eclipse t-shirts. Made from 100% organic cotton, these shirts are perfect in any climate. Represent this important issue while helping support Global Inheritance!'}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                            <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="polar-bear-magnet">
                          <div className="shop-product-image" style={{backgroundImage: `url(${magnetPolar})`}}></div>
                          <div className="shop-product-title">Polar Bear Thermometer Magnet</div>
                          <div className="shop-product-price">$7.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'MAGNET_POLAR'}
                            data-item-price={'7.00'}
                            data-item-weight={100}
                            data-item-image={magnetPolar}
                            data-item-name={'Polar Bear Thermometer Magnet'}
                            data-item-description={'It’s getting hot in here…should we take our clothes off?  90% of all scientists believe in climate change. Keep watch on global temperatures with our limited edition thermometer. These official Polar Bear magnets are perfect for your refrigerator or file cabinet. Support Global Inheritance and donate today to receive your own.'}
                            data-item-url={'stage-globalinheritance.herokuapp.com/'}>

                            <div className="btn primary">
                            <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>

                      <div className="shop-product" id="energy-battle-royal-comic">
                          <div className="shop-product-image" style={{backgroundImage: `url(${comicEnergy})`}}></div>
                          <div className="shop-product-title">Energy Battle Royal Comic Book</div>
                          <div className="shop-product-price">$5.00</div>
                          <a
                            href='#'
                            className='snipcart-add-item no-effects'
                            data-item-id={'COMIC_ENERGY'}
                            data-item-price={'5.00'}
                            data-item-weight={100}
                            data-item-image={comicEnergy}
                            data-item-name={'Energy Battle Royal Comic'}
                            data-item-description={'Who is the most powerful person in the world? Grab an Energy Battle Royal comic book to find out! The Energy Battle Royal is Global Inheritance’s highly charged energy education campaign. With a full lineup of 14 energy sources – from Petroleum and Solar to Nuclear and Ethanol, you’ll learn everything you need to know about energy and the pros and cons of each source. All of the characters are placed into a ring to see who will power the world. Our next step is to transform this comic book into a live performance, and we need your help! Donate now, and help us bring this live action event to cities around the world!'}
                            data-item-url={'/donate'}>

                            <div className="btn primary">
                            <span>Add to Cart</span>
                            </div>
                          </a>
                      </div>
                  </div>
              </div> */}
          </div> 
      </div>
      </Layout>
  );
}

export default Donate;
